<template>
  <div
    class="half-form max-w-full h-dvh flex flex-col items-center justify-center bg-white"
  >
    <main class="container h-dvh grid grid-cols-1 md:grid-cols-2 gap-4 my-5">
      <div class="h-dvh md:flex items-center justify-center hidden">
        <slot name="image">
          <NuxtImg
            src="/images/login.png"
            sizes="376px lg:760px"
            class="h-dvh"
          />
        </slot>
      </div>
      <div
        class="flex flex-col w-full h-full justify-center max-w-lg justify-self-center px-3 md:px-0"
      >
        <div
          class="flex flex-col w-full items-center bg-accent rounded py-8 px-4 md:px-16"
        >
          <slot name="header">
            <div class="mb-8 border-b-2 border-white">
              <NuxtImg
                class="max-w-full mb-10"
                src="/logo.png"
                sizes="100vw sm:50vw md:400px"
              />
            </div>
          </slot>
          <slot name="form" />
          <slot name="footer" />
        </div>
      </div>
    </main>
  </div>
</template>
